<template>
  <div>
    <job-list job-status="DRAFT"></job-list>
  </div>
</template>

<script>
import JobList from '@/containers/jobs/JobList'
export default {
  components: { JobList }
}
</script>

<style scoped>

</style>
